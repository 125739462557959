import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Autor } from '../models/Autor';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      fontFamily: 'Gotham Book, Roboto,Times New Roman,serif',
      display: 'flex',
      marginTop: '1em',
      marginBottom: '.5em',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'row',
        alignItems: 'left',
      },
    },
    avatar: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    image: {
      height: 30,
      marginRight: '0.83em',
    },
    title: {
      marginBottom: 0,
      color: 'black',
    },
    avatarDark: {
      top: '50%',
      msTransform: 'translateY(-50%)',
      transform: 'translateY(-50%)',
      fontSize: '2.5rem',
      color: 'white',
      backgroundColor: 'black',
      width: '80px',
      height: '80px',
      margin: 'auto',
    },
    avatarPic: {
      width: '80px',
      height: '80px',
      marginRight: 15,
      boxShadow: [
        '0 1px 2px rgba(0,0,0,0.05)',
        '0 2px 4px rgba(0,0,0,0.05)',
        '0 4px 8px rgba(0,0,0,0.05)',
        '0 8px 16px rgba(0,0,0,0.05)',
        '0 16px 32px rgba(0,0,0,0.05)',
        '0 32px 64px rgba(0,0,0,0.05)',
      ],
    },

    bio: { maxWidth: 250, fontSize: 12 },
  })
);

interface Props {
  // author?: string;
  // authorImg?: string;
  date?: string;
  authorObject?: Autor;
  colorText?: string;
}

const Author = ({ date, authorObject, colorText }: Props): JSX.Element => {
  const classes = useStyles();

  if (authorObject?.id === 'VectorAnálisis') {
    return <></>;
  }

  let prefix = '';
  if (authorObject?.nombre !== undefined) {
    const splitName = authorObject?.nombre.split(' ');

    if (splitName.length === 1) {
      const nameChar = splitName[0].charAt(0);
      prefix = nameChar.normalize('NFKD').replace(/[^\w]/g, '');
    }

    if (splitName.length >= 2) {
      const nameChar = splitName[0].charAt(0);
      const lastNameChar = splitName[1].charAt(0);
      const unNormalized = nameChar.concat(lastNameChar);
      prefix = unNormalized.normalize('NFKD').replace(/[^\w]/g, '');
    }
  } else {
    prefix = 'VA';
  }

  const avatar_src = getImage(authorObject?.avatar)?.images?.fallback.src ? getImage(authorObject?.avatar)?.images?.fallback.src : authorObject.avatar
  return (
    <>
      <div className={classes.container}>
        <div>
          <div className={classes.avatar}>
            {authorObject?.avatar && (
              <Avatar
                className={classes.avatarPic}
                alt={authorObject?.nombre}
                src={avatar_src}
              />
            )}
            {!authorObject?.avatar && (
              <Avatar className={classes.avatarDark}>{prefix}</Avatar>
            )}
          </div>
        </div>
        <div>
          {authorObject?.nombre && (
            <div style={{ color: colorText, fontWeight: 600 }}>
              {authorObject?.nombre}
            </div>
          )}
          {authorObject?.puesto && (
            <div style={{ color: colorText, fontWeight: 600, fontSize: 12 }}>
              {authorObject?.puesto}
            </div>
          )}
          {date && (
            <div
              style={{
                fontSize: 12,
                textTransform: 'capitalize',
                color: colorText,
              }}
            >
              {date}
            </div>
          )}
          <div
            style={{
              color: colorText,
              fontWeight: 300,
            }}
          >
            {authorObject?.twitter && (
              <a
                href={`https://twitter.com/${authorObject?.twitter}`}
                style={{ color: '#FF6600', fontSize: 12, fontWeight: 500 }}
              >
                {authorObject?.twitter}
              </a>
            )}
            <br />
            {authorObject?.bio && (
              <div className={classes.bio}>{authorObject?.bio}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Author.defaultProps = {
  // author: 'Vector Análisis',
  // authorImg:
  //   'https://images.ctfassets.net/rmz81tjgnatt/5lUaPcdzsT5ipE63OWm2Rj/e98928355712ae8e05138e89e39569d2/Group__1_.png',
  date: '',
  authorObject: {
    id: 'VectorAnálisis',
    nombre: 'Vector Análisis',
    twitter: 'vectoranalisis',
    bio: 'Vector Análisis',
    avatar: null,
  },
  colorText: 'white',
};

export default Author;
