import React from 'react';
import { graphql } from 'gatsby';
// import Image from 'gatsby-image';
import { Grid, Container } from '@material-ui/core';
import { ContentfulDiarioEconomico } from '../../types/graphql-types';
import { useDiarioEconomicoPost } from '../operations/queries';
import PostLayout from '../components/PostLayout';
import { PostPreview as PostPreviewProps } from '../models/PostPreview';
import { DiarioEconomico as DiarioEconomicoProps } from '../models/DiarioEconomico';
import IconTitle from '../components/IconTitle';
import { useTheme } from '@material-ui/core/styles';
import Newspaper from '../assets/icons/icon-Newspaper.svg';
import WorldMarkets from '../assets/icons/icon-WorldMarkets.svg';
import Index from '../assets/icons/icon-Index.svg';
import Airtable from '../components/Airtable';
// import NewZoom from '../components/NewZoom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Table as TableProps } from '../models/Table';
import getCategoryColor from '../utils/getCategoryColor';
import LightboxCarousel from '../components/LightboxCarousel';

import { renderRichText } from "gatsby-source-contentful/rich-text"
// import Document from '@contentful/rich-text-react-renderer';
import options from '../utils/getRichTextOptions';
import Author from '../components/Author';
import { Autor } from '../models/Autor';
import StickyBox from 'react-sticky-box';

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useIsClient from '../utils/isClient';
import { HeadApi } from '../components/HeadApi';

import Avatar from '@material-ui/core/Avatar';
import Twitter from '../assets/icons/twitter-x.svg';



interface Props {
  data: {
    contentfulDiarioEconomico: ContentfulDiarioEconomico;
  };
}

export const Head = ({data}) => {
  const post: DiarioEconomicoProps = useDiarioEconomicoPost(
    data.contentfulDiarioEconomico
  );
  // console.log("[POST HEAD]", post);
  return(
    <HeadApi
      title={post.title}
      description={JSON.parse(post.description.raw).content[0].content[0].value}
      img={post.fluid?.gatsbyImageData.images.fallback.src}
    />
  );
};

export default function DiarioEconomico({ data }: Props): JSX.Element {
  const post: DiarioEconomicoProps = useDiarioEconomicoPost(
    data.contentfulDiarioEconomico
  );

  const postPreview = (): PostPreviewProps => {
    const {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      authorObject,
      authorExchangeMarket,
      authorObjectElTecnicoOpina,
      content,
      tags,
    } = post;

    // const description = Document;
    return {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      authorObject,
      authorExchangeMarket,
      authorObjectElTecnicoOpina,
      content,
      tags,
    };
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const color = getCategoryColor('economico');

  // console.log('post diario econ');
  // console.log(post.authorExchangeMarket);
  const gatsbyImage = getImage(post.fluid2)

  const isClient = useIsClient();
  if(isClient){

    return (
      <>
        <PostLayout post={postPreview()} color={color}>
          <>
            {post.description && (
              <h2
                style={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  fontSize: '1.15em',
                }}
              >
                {renderRichText(post.description, options)}
              </h2>
            )}
            <>
              <Grid container>
                <Grid
                  // style={{ paddingRight: '10px', paddingLeft: '10px' }}
                  item
                  xs={12}
                  sm={12}
                  md={post.tables ? 4 : 3}
                >
                  <StickyBox offsetTop={100} style={{ width: '100%' }}>
                    {post.tables &&
                      post.tables.map((tabla: TableProps) => {
                        if (tabla.tipo == 'tarjeta') {
                          //tarjeta
                          return (
                            <Container
                              style={
                                mobile ? { paddingLeft: 0, paddingRight: 0 } : {}
                              }
                            >
                              <IconTitle icon={Index} title={tabla.titulo}>
                                <div style={!mobile ? { marginLeft: 40 } : {}}>
                                  <Airtable
                                    key={tabla.titulo}
                                    src={tabla.tabla}
                                    description={tabla.descripcion}
                                    card={tabla.tipo === 'tarjeta'}
                                  />
                                </div>
                              </IconTitle>
                            </Container>
                          );
                        }
                      })}
                  </StickyBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={mobile ? { zIndex: 0, background: 'white' } : {}}
                >
                  {post.textEconomyMexico && (
                    <IconTitle title="Economía de México" icon={WorldMarkets}>
                      {renderRichText(post.textEconomyMexico, options)}
                    </IconTitle>
                  )}
                  {post?.imagesEconomiaMexico && (
                    <LightboxCarousel images={post.imagesEconomiaMexico} />
                  )}

                  {post.textEconomyInternational && (
                    <IconTitle title="Economía Internacional" icon={WorldMarkets}>
                      {renderRichText(
                        post.textEconomyInternational,
                        options
                      )}
                    </IconTitle>
                  )}
                  {post?.imagesEconomiaInternacional && (
                    <LightboxCarousel images={post.imagesEconomiaInternacional} />
                  )}

                  {post?.authorEconomyInternational && (
                    <Author
                      authorObject={post.authorEconomyInternational}
                      colorText={'black'}
                      date={post.date}
                    />
                  )}

                  {post.fluid2 && (
                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                      {/* <Image fluid={post.fluid2} alt="slide-1" /> */}
                      <GatsbyImage image={gatsbyImage} alt="slide-1"/>
                    </div>
                  )}

                  {post.textExchangeMarket && (
                    <IconTitle title="Mercado de Cambios" icon={Newspaper}>
                      {renderRichText(
                        post.textExchangeMarket,
                        options
                      )}
                    </IconTitle>
                  )}

                  {post?.authorExchangeMarket && (
                    <div className='comentario-autor'
                     style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:"25px", background: "#fbfbfb", padding: "20px"}}
                    >
                      <div className='comentario-avatar-wrap' style={{display:"flex",alignItems:"center"}}>
                        <Avatar
                            className="comentario-avatar"
                            style={{width:"62px", height:"62px",marginRight:"20px"}}
                            alt=""
                            src={post.authorExchangeMarket?.avatar.gatsbyImageData.images.fallback.src}
                        />
                        <div className='comentario-content'>
                            <p className='autor-title' style={{margin:"0",fontFamily:"Gotham Bold", fontSize:"14px", color:"#7D7D7D"}}>{post.authorExchangeMarket?.nombre}</p>
                            <p className='comentario-date' style={{fontFamily:"Gotham Book",fontSize:"12px",color:"#7D7D7D",marginBottom:"0"}}>{post.date}</p>
                        </div>
                      </div>
                      <div className='comentario-social'>
                        <div className='icon-tw' style={{width:"100%", height:"24px", margin:"0", display:"flex", justifyContent:"end"}}>
                            <img src={Twitter}/>
                        </div>
                        <a href={"https://twitter.com/"+post.authorExchangeMarket?.twitter} target="_blank" className='comentario-tw' style={{fontFamily:"Gotham Bold",fontSize:"14px", color:"#FF5000", marginBottom:"0"}}>{post.authorExchangeMarket?.twitter}</a>
                      </div>
                    </div>
                  )}

                  {post?.imagesMercadoDeCambios && (
                    <LightboxCarousel images={post.imagesMercadoDeCambios} />
                  )}

                  {post.authorsExchangeMarket &&
                    post.authorsExchangeMarket.map((autor: Autor) => {
                      return (
                        <Author
                          key={autor.id}
                          authorObject={autor}
                          colorText="black"
                        />
                      );
                    })}

                  {post.textOtherNews && (
                    <IconTitle title="Otras Noticias" icon={Newspaper}>
                      {renderRichText(post.textOtherNews, options)}
                    </IconTitle>
                  )}
                  {post.authorsOtherNews &&
                    post.authorsOtherNews.map((autor: Autor) => {
                      return (
                        <Author
                          key={autor.id}
                          authorObject={autor}
                          colorText="black"
                        />
                      );
                    })}

                  {/* El Tecnico Opina */}
                  {post?.textElTecnicoOpina && (
                    <IconTitle title="El Técnico Opina" icon={Newspaper}>
                      {renderRichText(
                        post.textElTecnicoOpina,
                        options
                      )}
                    </IconTitle>
                  )}
                  {/* {post?.authorObjectElTecnicoOpina && (
                    <Author
                      authorObject={post.authorObjectElTecnicoOpina}
                      colorText={'black'}
                      date={post.date}
                    />
                  )} */}
                  {post?.imagesElTecnicoOpina && (
                    <LightboxCarousel images={post.imagesElTecnicoOpina} />
                  )}
                  {post?.tables &&
                    post.tables.map((tabla: TableProps) => {
                      if (tabla.tipo == '50%') {
                        return (
                          <>
                            <IconTitle icon={Index} title={tabla.titulo}>
                              <Airtable
                                src={tabla.tabla}
                                description={tabla.descripcion}
                                card={false}
                              />
                            </IconTitle>
                          </>
                        );
                      }
                    }
                  )}

                  {post?.authorObjectElTecnicoOpina && (
                    <div className='comentario-autor'
                     style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:"25px", background: "#fbfbfb", padding: "20px"}}
                    >
                      <div className='comentario-avatar-wrap' style={{display:"flex",alignItems:"center"}}>
                        <Avatar
                            className="comentario-avatar"
                            style={{width:"62px", height:"62px",marginRight:"20px"}}
                            alt=""
                            src={post.authorObjectElTecnicoOpina?.avatar.gatsbyImageData.images.fallback.src}
                        />
                        <div className='comentario-content'>
                            <p className='autor-title' style={{margin:"0",fontFamily:"Gotham Bold", fontSize:"14px", color:"#7D7D7D"}}>{post.authorObjectElTecnicoOpina?.nombre}</p>
                            <p className='comentario-date' style={{fontFamily:"Gotham Book",fontSize:"12px",color:"#7D7D7D",marginBottom:"0"}}>{post.date}</p>
                        </div>
                      </div>
                      <div className='comentario-social'>
                        <div className='icon-tw' style={{width:"100%", height:"24px", margin:"0", display:"flex", justifyContent:"end"}}>
                            <img src={Twitter}/>
                        </div>
                        <a href={"https://twitter.com/"+post.authorObjectElTecnicoOpina?.twitter} target="_blank" className='comentario-tw' style={{fontFamily:"Gotham Bold",fontSize:"14px", color:"#FF5000", marginBottom:"0"}}>{post.authorObjectElTecnicoOpina?.twitter}</a>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
              <>
                {post.tables &&
                  post.tables.map((tabla: TableProps) => {
                    if (tabla.tipo == '100%') {
                      return (
                        <>
                          <IconTitle icon={Index} title={tabla.titulo}>
                            <Airtable
                              src={tabla.tabla}
                              description={tabla.descripcion}
                              card={false}
                            />
                          </IconTitle>
                        </>
                      );
                    }
                  })}
              </>
            </>
          </>
        </PostLayout>
      </>
    );
  }else{
    return(<></>)
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulDiarioEconomico(slug: { eq: $slug }) {
      ...diarioEconomico
    }
  }
`;
